.top_right_btns i[data-v-5d2ea7f4] {
  cursor: pointer;
  color: var(--themeColor, #17a2b8);
  font-size: 18px;
}
.top_right_btns div[data-v-5d2ea7f4] {
  margin-right: 5px;
}
.top_btns[data-v-5d2ea7f4] {
  position: absolute;
  display: inline;
  top: 8px;
  right: 16px;
  z-index: 999;
}
.item_icon[data-v-5d2ea7f4] {
  display: inline-block;
  cursor: pointer;
}
.item_icon i[data-v-5d2ea7f4] {
  display: inline-block;
  font-size: 13px;
  margin-right: 4px;
}
.item_icon .iconfont icon-cuowu[data-v-5d2ea7f4] {
  color: #f56c6c;
}
.item_icon .iconfont icon-duihao[data-v-5d2ea7f4] {
  color: #67c23a;
}
.item_icon .el-icon-circle-check[data-v-5d2ea7f4] {
  color: #67c23a;
}
.item_icon .iconfont icon-info[data-v-5d2ea7f4] {
  color: #409eff;
}
.item_icon .iconfont icon-cuowu[data-v-5d2ea7f4] {
  color: #e6a23c;
}
.item_icon .iconfont icon-cuowu-outline[data-v-5d2ea7f4] {
  color: #e6a23c;
}
.item_icon p[data-v-5d2ea7f4] {
  display: inline-block;
  font-size: 13px;
}
.hint[data-v-5d2ea7f4] {
  font-size: 13px;
  color: red;
  margin-right: 10px;
}
.hint .icon-gantanhao1[data-v-5d2ea7f4] {
  font-size: 13px;
  margin-right: 3px;
}