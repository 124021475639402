.iconfont icon-bianji[data-v-05c316d0], .iconfont icon-shanchu[data-v-05c316d0], .el-icon-setting[data-v-05c316d0] {
  margin: 0 5px;
  cursor: pointer;
  color: var(--themeColor, #17a2b8);
  font-size: 18px;
  line-height: 24px;
}
.dialog_table[data-v-05c316d0] {
  width: 100%;
  margin: 0 auto;
  border: 1px solid #ccc;
  border-bottom: 0;
}
.dialog_table tr[data-v-05c316d0] {
  line-height: 32px;
  height: 32px;
}
.dialog_table td[data-v-05c316d0] {
  border-right: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  padding: 0 10px;
  font-size: 14px;
}
.dialog_table td[data-v-05c316d0]:last-child {
  border-right: 0;
}
.bottom_font[data-v-05c316d0] {
  font-size: 14px;
  color: red;
  line-height: 20px;
  margin-top: 20px;
}